import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import { Link } from "gatsby";

const Calltoaction = (props) => {
    const calltoactionData = useStaticQuery (graphql`
        query calltoactionQuery {
            calltoactionJson {
                title
                buttonText
            }
        }
    `);
    const Title = calltoactionData.calltoactionJson.title;
    const ButtonText = calltoactionData.calltoactionJson.buttonText;
    return (
        <div className="rn-callto-action-area callto-action-style-1 ptb--50" style={{backgroundColor: props.backgroundColor}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="inner">
                            <h4 className="title" style={{color: props.color}}>{Title}</h4>
                        </div> 
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                        <div className="action-btn text-left text-md-right">
                            <Link className="rn-button btn-white" to="/contact"><span>{ButtonText}</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

    Calltoaction.defaultProps = {
        backgroundColor: "var(--color-primary)",
        color: "#fff"
    }

export default Calltoaction
