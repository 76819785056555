import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Projectcard from "./projectcard";

const ProjectOne = () => {
    const ProjectData = useStaticQuery(graphql`
        query ProjectDataQuery {
            allProjectJson(limit: 3) {
                edges {
                  node {
                    id
                    title
                    category
                    url
                    technologies
                    body
                    featured_image {
                        childImageSharp {
                            fluid(maxWidth: 360, maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                  }
                }
            }
        }
    `);

    const projectsData = ProjectData.allProjectJson.edges;
    return (
        <div className="row row--25">
            {projectsData.map( data => (
                <Projectcard key={data.node.order}
                column="col-lg-4 col-md-6 col-12"
                portfolioStyle="portfolio-style-1"
                image={data.node.featured_image.childImageSharp}
                title={data.node.title}
                url={data.node.url}
                category={data.node.category}
                technologies={data.node.technologies}
                description={data.node.body}
        />
            ))}
        </div>
    )
}

export default ProjectOne;