import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import ProjectOne from "../../elements/project/projectOne";
import Img from "gatsby-image";



const Project = () => {
    const portfolioData = useStaticQuery(graphql`
        query portfolioDataQuery {
            file(relativePath: {eq: "images/banner/code.png"}) {
                childImageSharp {
                    fluid (maxWidth: 500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                }
            },
            homedefaultJson {
            title
            description
            subtitle
            }
        }
    `);
    
    const BgshapeImages = portfolioData.file.childImageSharp.fluid;
    const subtitle = portfolioData.homedefaultJson.subtitle;
    return (
        <div className="rn-portfolio-area rn-section-gap portfolio-style-1 line-separator" id="portfolio">
            <div className="container">
                <div className="row mb--10 align-items-center">
                    <div className="col-lg-6">
                        <div className="section-title">
                            <span className="subtitle">{subtitle}</span>
                            <h2 className="title title-project">Prestations en développement Web</h2>
                            <p>J'ai plus de 15 ans d'expérience dans le développement de sites Web.<br></br>
                            La création Web a beaucoup évolué depuis 15 ans. Des sites statiques en HTML, nous sommes passés aux CMS (outils de gestion de contenu) tels que Wordpress ou Joomla.<br></br>
                            Les nouvelles possibilités offertes par certains langages et outils font que nous revenons aux sites statiques... Cela n'a pas fini d'évoluer.</p>
                        <p>Vous avez besoin d'un site Web pour vendre/promouvoir vos produits/services ?<br></br>
                        Je développe des sites vitrine et des sites d'e-commerce à destination des PME/PMI, indépendants et associations. <br></br>Chaque projet étant spécifique, je vous propose un outil adapté à vos besoins et à votre budget.</p>
                        <p><strong>Vous trouverez ci-dessous quelques-uns de mes projets les plus récents :</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="thumbnail">
                                <div className="thumbnail-inner text-center">
                                    <Img className="portfolio-images" fluid={BgshapeImages} alt="Développement de sites Web pays de Gex et Genève"/>
                                </div>
                            </div>
                    </div>

                </div>
               

                <ProjectOne />
                <div className="ror">
                    <div className="col-lg-12">
                        <div className="project-bottom">
                            <Link className="rn-button" to="/dev-services"><span>Voir tous les projets</span></Link>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    )
}
export default Project
