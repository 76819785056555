import React, {useState} from 'react';
import Image from "../image";
import Img from 'gatsby-image'


const Projectcard = ({ image, title, category, order, description, url, column }) => {

    const [show, setShow] = useState(false);

    let projectImg;
    if (image.fixed && typeof image.fixed !== 'function') {
        projectImg = <Img fixed={image.fixed} alt={title} />;
    } else if (image.fluid) {
        projectImg = <Image fluid={image.fluid} alt={title} />
    } else {
        projectImg = <img src={image.src} alt={title} />
    }

    
    return (
        <div className={column}>
            <div className="portfolio">
                <div className="thumbnail">
                <a href={url} target="_blank" rel="noopener noreferrer">
                        {projectImg}
                    </a>
                </div>
                <div className="content">
                    <div className="inner">
                        <div className="title_abs">
                            <div className="layer"></div>
                        </div>
           
                        {category && <span className="category"><a href={url} target="_blank" rel="noopener noreferrer">{category}</a></span>}
                        {title && <h4 className="title"><a href={url} target="_blank" rel="noopener noreferrer">{title}</a></h4>}
                        <p><button className="description--link" onClick={() => setShow(!show)}>Plus d'infos</button></p>
                        {description && <p className={`description ${show ? "description--active": ""}`}>{description}</p>}

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Projectcard;
