import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from "gatsby-image";
import { Link } from "gatsby";

const Banner = () => {
    const banenrQueryData = useStaticQuery (graphql`
        query BannerDefaultQuery {
            homedefaultJson {
                title
                subtitle
                description
                buttonText
              },
            file(relativePath: {eq: "images/banner/eye5.png"}) {
                childImageSharp {
                  fluid (maxWidth: 500, quality: 100) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
            }
        }
    `);
    const PortfolioImages = banenrQueryData.file.childImageSharp.fluid;
    const Title = banenrQueryData.homedefaultJson.title;
    const SubTitle = banenrQueryData.homedefaultJson.subtitle;
    const description = banenrQueryData.homedefaultJson.description;
    const buttonText = banenrQueryData.homedefaultJson.buttonText;
    return (
        <div className="rn-slider-area" id="home">
            {/* Start Single Slider  */}
            <div className="rn-slide slider-style-01 banner-fixed-height">
                <div className="wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="order-2 order-xl-1 col-xl-6 col-lg-12 col-md-12 col-12">
                                <div className="inner mt_lg--40 mt_md--40 mt_sm--40">
                                    <div className="content text-left">
                                        <span className="subtitle theme-color">{SubTitle}</span>
                                        <h1 className="title" dangerouslySetInnerHTML={{ __html: Title }}></h1>
                                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                        <Link className="rn-button" to="/contact"><span>{buttonText}</span></Link>
                                        <ul className="social-icons">
                                            <li><a href="https://twitter.com/marie_luce" target="_blank" rel="noreferrer noopener"><span>Twitter</span></a></li>
                                            <li><a href="https://www.linkedin.com/in/marieluceberaudo/" target="_blank" rel="noreferrer noopener"><span>LinkedIn</span></a></li>
                                            <li><a href="https://www.tiktok.com/@seoalternative" target="_blank" rel="noreferrer noopener"><span>TikTok</span></a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-xl-2 col-xl-6 col-lg-12 col-md-12 col-12">
                                <div className="thumbnail">
                                    <div className="thumbnail-inner">
                                        <Img className="mx-auto" fluid={PortfolioImages} alt="SEO Alternative - Création et référencement de sites Internet" loading="eager"/>
                                    </div>
                                </div>

                            </div>   
                        </div>
                    </div>
                </div>
            </div>
            {/* End Single Slider  */}
        </div>
    )
}
export default Banner;