import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/homedefault/banner";
import About from "../components/homedefault/about";
import Service from "../components/homedefault/service";
import Project from "../components/homedefault/project";
import Calltoaction from "../elements/calltoaction/calltoaction";
import BlogPost from "../components/blogPost";


const IndexPage = () => (
  <Layout>
    <Seo 
    title="Création de sites Internet et SEO pays de Gex et Genève - Marie-Luce Beraudo, SEO Alternative" 
    description="SEO Alternative, micro-entreprise basée dans le pays de Gex près de Genève, vous propose des prestations en référencement naturel/SEO ainsi qu'en développement Web."
    />
    <Banner />
    <About />
    <Service />
    <Calltoaction />
    <Project />
    <BlogPost />
  </Layout>
  
)
export default IndexPage;