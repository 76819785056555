import React from 'react'
import {useStaticQuery, graphql} from 'gatsby';
import Img from "gatsby-image";
import { FiMapPin, FiGift, FiBookOpen , FiBook, FiUser} from "react-icons/fi";
import { Link } from "gatsby";

const infoList = [

    {
        "id": "1",
        "icon": <FiMapPin />,
        "label": "Situation géographique :",
        "link": "Pays de Gex, France / Genève, Suisse"
    },
    {
        "id": "2",
        "icon": <FiBookOpen />,
        "label": "Langues :",
        "link": "français, anglais"
    },


    {
        "id": "3",
        "icon": <FiBook />,
        "label": "Formation :",
        "link": "Master en communication avec une spécialisation e-commerce"
    },
    {
        "id": "4",
        "icon": <FiUser />,
        "label": "Statut :",
        "link": "Micro-entreprise"
    },
    {
        "id": "5",
        "icon": <FiGift />,
        "label": "Mon offre :",
        "link": "une approche alternative pour votre SEO"
    },

]


const About = ( ) => {
    const aboutQueryData = useStaticQuery(graphql`
        query AboutDefaultQuery {
            aboutJson {
                title
                description
                downloadButton
                linkBUtton
            },
            file(relativePath: {eq: "images/banner/Marie-Luce-Beraudo-photo.jpg"}) {
                childImageSharp {
                  fixed (quality: 100, width: 395, height: 470) {
                    ...GatsbyImageSharpFixed
                  }
                }
            }
        }
    `);

    const PortfolioImages = aboutQueryData.file.childImageSharp.fixed;
    const title = aboutQueryData.aboutJson.title;
    const description = aboutQueryData.aboutJson.description;
    const downloadButton = aboutQueryData.aboutJson.downloadButton;
    const linkBUtton = aboutQueryData.aboutJson.linkBUtton;

    return (
        <div className="rb-about-area about-style-01 rn-section-gap line-separator bg-color-light" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <span className="subtitle">BIO</span>
                            <h2 className="title">A propos</h2>
                        </div>
                    </div>
                </div>
                <div className="row mt--60 mb--30 row--25">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="thumbnail">
                            <div className="image">
                                <Img className="portfolio-images" fixed={PortfolioImages} alt="Marie-Luce Beraudo, consultante Web SEO"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 mt_md--40 mt_sm--40">
                        <div className="inner">
                            <div className="content">
                                <div className="section-title">
                                    {title && <h3 className="title" dangerouslySetInnerHTML={{ __html: title }}></h3>}
                                    {description && <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>}
                                </div>
                                {infoList &&
                                    <ul className="myabout-list">
                                        {infoList.map((value, index) => (
                                            <div className="list flex-column flex-md-row" key={index}>
                                                <div className="d-flex">
                                                <div className="icon">{value.icon}</div>
                                                <span className="label">{value.label}</span>  
                                                </div>
                                                <div>{value.link}</div>
                                              
                                            </div>
                                        ))}
                                    </ul>
                                }
                                <div className="button-group mt--20">
                                    {downloadButton && <a className="rn-button" href="https://www.linkedin.com/in/marieluceberaudo/" target="_blank" rel="nofollow noreferrer" style={{background: "initial"}}><span>{downloadButton}</span></a>}
                                    {linkBUtton && <Link className="rn-button" to="/contact"><span>{linkBUtton}</span></Link>}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
   
        </div>
    )
}

export default About
