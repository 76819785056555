import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ServiceOne from "../../elements/service/serviceOne"
import Img from "gatsby-image"

const Service = () => {
  const bgshapeImages = useStaticQuery(graphql`
    query BgshapeImagesQuery {
      file(relativePath: { eq: "images/banner/eye5.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const BgshapeImages = bgshapeImages.file.childImageSharp.fluid

  return (
    <div className="rn-section-gap line-separator">
      {/* Start Service Area  */}
      <div className="rn-service-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col lg-6">
              <div className="section-title mb--40">
                <span className="subtitle">OFFRE</span>
                <h2 className="title orange">Prestations SEO</h2>

                <p>
                  J’ai lancé
                  <strong>
                    <span className="theme-color"> SEO </span>
                    <span className="secondary-color">Alternative </span>
                  </strong>
                  pour aider les équipes de marketing digital qui ont
                  l'impression de stagner dans leur stratégie de référencement
                  naturel. Bien souvent, ces équipes ont déjà certaines
                  connaissances en SEO. Ce qui leur manque est surtout un <strong>regard externe</strong> qui les aide à consolider
                  leurs compétences et à <strong>définir une stratégie</strong> qui fonctionne sur la durée.
                </p>
                <p>
                  Peut-être hésitez-vous à externaliser complètement votre SEO,
                  et c’est bien normal. Je vous déconseille d’abandonner votre
                  SEO à un prestataire externe qui vous fera payer tous les mois
                  pour du travail un peu obscur que vous ne comprenez pas
                  réellement.
                </p>
                <p>
                  <strong>
                    Avant d’externaliser son SEO, mieux vaut déjà bien
                    comprendre comment fonctionne le SEO.
                  </strong>
                </p>

                <p>
                  Voilà pourquoi je vous propose une approche alternative : je
                  vous aide à <strong>gérer vous-même votre SEO</strong>.
                </p>
                <p>
                  Ma démarche passe par <strong>3 étapes</strong> :
                </p>

                <ol>
                  <li className="theme-color">
                    <strong>L'audit SEO</strong>
                  </li>
                  <li className="secondary-color">
                    <strong>Le coaching SEO</strong>
                  </li>
                  <li className="white-color">
                    <strong>Le suivi SEO</strong>
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="thumbnail">
                <div className="thumbnail-inner text-center">
                  <Img
                    className="portfolio-images mx-auto"
                    fluid={BgshapeImages} alt="Prestations SEO - audit SEO, coaching SEO, suivi SEO"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row row--35">
            <ServiceOne
              column="col-lg-4 col-md-6 col-12"
              serviceStyle="service-style-1 mt--30"
            />
          </div>
        </div>
      </div>
      {/* End Service Area  */}
    </div>
  )
}

export default Service
